import React from 'react';

import rio2016 from '../../access/img/rio2016.png';

import olympics_1 from '../../access/img/olympics_1.png';
import olympics_2 from '../../access/img/olympics_2.png';
import olympics_3 from '../../access/img/olympics_3.png';

import olympics_4 from '../../access/img/olympics_4.png';
import olympics_5 from '../../access/img/olympics_5.png';


import olympics_6 from '../../access/img/olympics_6.png';
import olympics_7 from '../../access/img/olympics_7.png';
import olympics_8 from '../../access/img/olympics_8.png';
import olympics_9 from '../../access/img/olympics_9.png';

import olympics_10 from '../../access/img/olympics_10.png';
import olympics_11 from '../../access/img/olympics_11.png';
import olympics_12 from '../../access/img/olympics_12.png';
import olympics_13 from '../../access/img/olympics_13.png';

import olympics_14 from '../../access/img/2016-07-30_14.17.12.jpg';
import olympics_15 from '../../access/img/2016-08-05_23.44.20.jpg';
import olympics_16 from '../../access/img/2016-08-12_10.19.48.jpg';
import olympics_17 from '../../access/img/2016-08-13_12.06.30.jpg';
import olympics_18 from '../../access/img/Rio_2016_Olympic_medical_team.jpg';

import olympics_19 from '../../access/img/M77A1472.jpeg';
import olympics_20 from '../../access/img/M77A1214b.JPG.jpg';
import olympics_21 from '../../access/img/M77A1166.jpeg';
import olympics_22 from '../../access/img/M77A0254.jpeg';
import Page from "../module/include/Page/Page";

class Price extends React.Component {
    state = {
        title: 'Olympics'
    };

    render() {
        return (<Page title={this.state.title}>

            <section className="olympics price">
                <div className="container">
                    <h2 className="sectionTitle">Appointments and Price</h2>
                    <h3 className="sectionSubtitle">To book an appointment you can call us directly on</h3>
                    <div className="olympics__top">
                        <div className="olympics__title">0208 643 8000 or 0208 685 6930 (After Hours)</div>
                        <div className="olympics__desc">
                            <p>We are available to take bookings from 8am – 8.30pm Monday to Thursday, 9am – 5pm Fridays and 9am – 3pm Saturdays or you can email us:</p>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <div className="olympics__title">info@surreyphysio.co.uk</div>
                        <div className="olympics__desc">
                            <p>We will respond via e-mail or telephone, as soon as possible, advising you of our availability.</p>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <div className="olympics__title">Physiotherapy</div>
                        <div className="olympics__desc">
                            <p>Initial Consultation: £40.00  (30 minutes)
                                Subsequent Treatments: £40.00  (30 minutes)</p>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <div className="olympics__title">Osteopathy</div>
                        <div className="olympics__desc">
                            <p>Initial Consultation: £40.00  (30 minutes)
                                Subsequent Treatments: £40.00  (30 minutes)</p>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <div className="olympics__title">Pilates</div>
                        <div className="olympics__desc">
                            <p>1:1   £55.00 (1 hour)
                                4:1 class = £15.00 (1 hour)</p>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <i>Please note that there may be a small charge added for the use of credit cards at some of our clinics.</i>

                        <div>
                            <div className='insCode'>Insurance Codes:</div><br/>
                            <div className='insC'>
                                <div>BUPA Physio:</div> 80009032<br/>
                                <div>BUPA Osteopathy:</div> 30032104<br/>
                                <div>AXA/PPP Physio:</div> ZZ01298 & ZZ01299<br/>
                                <div>AXA/PPP Osteopathy:</div> TA00652<br/>
                                <div>Aviva:</div> 600033966<br/>
                                <div>WPA Physio:</div> 3926528<br/>
                                <div>WPA Osteopathy:</div> 920272028<br/>
                            </div>

                            <div className='please_note'>
                                Please note: Your insurance is your responsibility.
                                We cannot be held accountable if your insurance does not pay us.
                                You are always responsible for your cover, so if your insurance does not pay,
                                we will ask you for the payment and you can reclaim it direct from the insurance company.
                                We also require the excess to be paid on the first appointment at Surrey Physio.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}

export default Price;
