import React, { Component, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'

import banner3 from '../access/img/slide/3.jpg';
import banner4 from '../access/img/slide/4.jpg';
import banner5 from '../access/img/slide/5.jpg';
import banner6 from '../access/img/slide/6.jpg';

class SliderHome extends React.Component {

    render() {
        const defaultProps = {
            slide: true,
            fade: false,
            controls: true,
            indicators: true,

            defaultActiveIndex: 0,
            interval: 5000,
            keyboard: true,
            // pause: 'hover' as CarouselProps['pause'],
            wrap: true,
            touch: true,

            prevIcon: <span aria-hidden="true" className="carousel-control-prev-icon" />,
            prevLabel: '',

            nextIcon: <span aria-hidden="true" className="carousel-control-next-icon" />,
            nextLabel: '',
        };
        Carousel.defaultProps = defaultProps;
        return (<section className="banner">
            <div className="banner-carousel">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner5}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner6}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner3}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={banner4}
                            alt="Third slide"
                        />

                    </Carousel.Item>
                </Carousel>
            </div>

            <div className="container">
                <div className="bannerText">
                    <h2 className="bannerTitle">A friendly, professional and affordable service</h2>
                </div>
            </div>
        </section>);
    }
}


export default SliderHome;
