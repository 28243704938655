import React from 'react';
import brand from '../../access/img/home/brand.jpg';

const  HomeBlock2 = () => {
    return <section className="service">
        <div className="container">
            <div className="desc">
                <img src={brand} />
            </div>
        </div>
    </section>
}
export default HomeBlock2;