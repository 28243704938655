import React from 'react';
import testimonial from "../../access/img/testimonial.jpg";
import Page from "../module/include/Page/Page";

class Testimonials extends React.Component {
    state = {
        title: 'Testimonials'
    };

    render() {
        return (<Page title={this.state.title}>
            <section className="testimonials">
                <div className="container">
                    <h2 className="sectionTitle">Client Testimonials</h2>
                    <div className="testimonial"><img src={testimonial} /></div>
                    <div className="text">
                        I had a tremendously painful right shoulder, it came out of nowhere – I couldn’t move it and it was extremely painful, I went to A&E which showed what seemed to be an extra piece of bone on my shoulder. My GP hadn’t really got a clue and was referring me to the specialist, which was going to take several weeks. I went to see Julie at Surrey physio. I explained the issue, she instantly put me at ease – being my first time at a physio, she asked a lot of questions and she suspected a build up of calcium deposits from when I played county badminton – over 20 years previously, which the specialist finally confirmed a few weeks later. After a series of treatments with Julie and exercises she gave me to do at home the pain left me very fast. By the 2nd time I saw the specialist the calcium deposits had gone (after they had said I would l probably need an operation to scrape it off my bone) and I had full working use of my am again. The specialists were surprised to see it had disappeared entirely and they said that the only potential explanation for this was the physio treatment that I had been receiving as I was only taking painkillers to that point.
                        Julie was fantastic, she always understood my pain threshold, firm but gentle, she developed my treatments as I got better and she was always very friendly and professional, as were the whole gang there whenever I saw them. In a weird way I wish I still had some pain so I could keep going. You don’t get that often – people wanting to have pain to be able to go and see their physiotherapist. I would highly recommend Surrey Physio. They saved my pain, they saved me having an operation and a scar for life. Thank you Julie and the Gang at Surrey Physio.
                    </div>
                    <strong>Robert Allan</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was recently referred to Surrey Physio centre by my GP as I had an on going ankle injury. Once I got there for the first time the staff were so helpful. Starting from the receptionist all the way up to the Physiotherapist who was brilliant. He helped me to over come my problem and his advise was very useful and very helpful. The service was great. I would like to send out a great thank you to the staff at Surrey Physio and most especially to my ex physiotherapist Martin whose surname I did not get. Thank you for such a great service.
                    </div>
                    <strong>Benjamin Bengtson</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was referred by my Doctor for physiotherapy treatment following a hip replacement that had remained painful for over a year. I received treatment from Martin who gave me exercises, helpful advice, and was very patient and informative throughout. Thank you Martin. My hip problem/walking has improved, and I am confidence this will continue as I continue with the exercises I have been given.
                        Thank you to Surrey Physio for excellent treatment and also the doctor for referring me.
                    </div>
                    <strong>Mrs Sheila Jarvis</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was impressed with my first visit to Surrey Physio, I went in on time to my appointment and found the staff very pleasant. When I next visit my Doctor I will tell him how good the service was I received.
                    </div>
                    <strong>Mary Robson</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I was referred to Wallington Physio through my GP. I was very impressed at how quickly I was offered an appointment, it is also great that you offer late evening appointments too, because time away from work, especially at the moment is not appreciated. I have only been for one appointment with Faith Cripps, who was great and seemed to find my problem straight away. I look forward to seeing her for my second appointment soon. Service was excellent and punctual. Thank you
                    </div>
                    <strong>Mrs E Compton</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I have recently finished having treatment referred to you by my GP at Shotfield Health Centre. I saw Martin Bull for the most of my physiotherapy and he has really helped me with my pelvis. I’m not completely pain free, that will take time, but Martin gave me exercises to do at home and I’m making progress. Both Martin and Stuart were extremely helpful and very friendly. I would recommend them to anyone.
                    </div>
                    <strong>Cheryl Gough</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Dear Wallington Physio, I would like to thank Mark Archer for the excellent service and the care that was shown to John especially after his short stay in St Helier Hospital after collapsing on me and hurting his back.
                    </div>
                    <strong>Beryl Stephens</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I was seen by my G.P. on a Friday morning and had a phone call the next day asking me to arrange an appointment to see a physio.  I was given one about a week later.   I found the Physio very helpful and he explained the problem with my shoulder and what exercises I should do.  I have a follow up appointment in about two week time.
                        I am very satisfied with the service”
                    </div>
                    <strong>Peter Beckett</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I have been attending your clinic for 5 sessions after a referral from my GP, Dr R Dowding The Knoll Cheam Village.I have been seeing your physiotherapist – Martin, who has been most helpful in trying to remedy my complaint. I have been happy with the service that I have received. Many Thanks
                    </div>
                    <strong>Dorothy Chubb</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I am writing after yesterday completing four sessions of assessment and treatment with you for my thoracic spine problems.
                        Thank you very much for a thoroughly professional service. I really valued the opportunity of early appointments 7.30-8am, and was seen promptly on each occasion. Most importantly you identified my problem and have made a huge difference over 6 weeks to the point where I feel confident I can manage it ok with the aid of the exercises you have shown me, Thanks again
                    </div>
                    <strong>Sean Hilton</strong><i>Deputy Principal and Professor of Primary Care St. George’s, University of London</i>
                </div>

                <div className="container">
                    <div className="text">
                        I was recently treated at the Surrey Physio. I had severe shin pain whenever I ran in football boots which the Physio, Martin thought was due to tight calves. I was very impressed with the professional service that I received and the quickness of appointments and waiting times. The treatment seemed to be going well and when I returned to football 3/4 weeks later I had no problems for 2 weeks and was therefore discharged from the clinic. Many Thanks.
                    </div>
                    <strong>Luke Hales</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I have made 2 visits to Surrey Physio and so far I am very happy with the service you have provided. The receptionist was most welcoming and helpful. Stuart and Martin were very kind and gave me excellent treatment.
                    </div>
                    <strong>Grace Cooper</strong>
                </div>

                <div className="container">
                    <div className="text">
                        Dear Tim, Thank you for your excellent treatment centre. The reception area is very comfortable and clean and the receptionists polite and caring. Mark Archer the physiotherapist is very good. John has only had two sessions of physiotherapy on his neck and he said that after the 2nd session his neck felt much better and he could turn his neck a lot easier. There is no problem with parking and with public transport it is very easy as several bus routes pass the entrance to Mint House. Thanks for all your help.
                    </div>
                    <strong>Mrs Joyce Springs</strong>
                </div>

                <div className="container">
                    <div className="text">
                        I had my first physio session with Martin yesterday so I wanted to say how impressed I was with your staff. He was attentive and explained things well, and I was happy with the treatment.
                    </div>
                    <strong>James Harding</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Just to let you know that I am sat straight at my desk (no crossed legs) and at the moment there are no ill effects from yesterday. I wanted to say thank you so very much for all your help and support over the past 6 months. Progress was so slow to start with initial treatment before Surrey Physio and I feel very luck to have been sent to you. You have a wonderful empathy with your clients and a really positive energy that can’t but fail to rub off.
                        I will genuinely be sad to finish next week!
                    </div>
                    <strong>Odette Butson</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I would just like to thank Mark and the rest of the team for my recent treatment at Surrey Physio. My back is much better now. I was also grateful for your friendly and relaxed approach. I would recommend your service highly.
                    </div>
                    <strong>Nasr Eddine Mennaceur</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Just a short note to thank the Surrey Physio team, and especially my physio Stuart for his helpful back massaging treatment. Thankfully my back seems a lot better, and is improving very well so I am not taking nay pain killing tablets. Hopefully I will not have to return for more treatment in the future!
                        Yours sincerely,
                    </div>
                    <strong>Mrs P Watkins</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Dear Mr Allardyce, I would like to thank you and your excellent staff at the Surrey Physio centre for the care which I received. The physio that I saw on my visits was Gitte. I was seen on time, and with respect. Had I been a private patient, I could not have been treated any better. I have informed my G.P. Once again, thank you.
                        Yours faithfully,
                    </div>
                    <strong>Patricia Gates</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I am writing to say thank you for your excellent service at Surrey Physio, I found the staff to be pleasant and friendly, your waiting room clean and comfortable, and I liked that fact that your consulting rooms are rooms, and not just a cubicle with a curtain drawn across. I was contacted by a member of your staff the day after my doctor faxed a referral letter to you, and was offered an appointment within a few days. All in all, I was very pleased with your excellent service.
                        Yours sincerely,
                    </div>
                    <strong>Lorraine Morgan</strong>
                </div>
                <div className="container">
                    <div className="text">
                        Rather unusually, I am taking time to put pen to paper to thank, congratulate, and wish you all well at Surrey Physio. As you know, I needed to see Mark today and he is officially in my GOD category – he fixed me today good and proper and I am convinced he can walk on water. Lorraine is delightful and always makes time to have a chat with me when I visit no matter how busy she is. It is clear she runs your office so efficiently I was wondering if you could allow her to work for me, lets say 35 hours a week? (I am sure she would agree?). Seriously though Tim, thank you to you all for everything that you have done and continue to do to help me maintain this irritating back problem I have. I just want to wish you all the very best for the future.
                        With kind regards, and best wishes,
                    </div>
                    <strong>Phillip Allsopp</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I would like to assure you that I was very favourably impressed with both the concern Mark showed me for my ailing knee and the professional expertise he demonstrated in dealing with it. In fact, I was not looking for promises, and as Mark said, the problem has not gone away. But the treatment I received was soothing and made me much more aware of movements I have to avoid – which means I have so far managed to avoid those painful moments that made me seek help through my G.P., Dr. Goel. Hence, my best wishes to Surrey Physio. I do hope your staff will strive to maintain the present standards. Mark, friendly and impeccably polite at the same time, was equally intent on exercise and giving pointed explanations – which is no doubt the best method to make the patient feel that he is being treated as an individual. Yours sincerely,
                    </div>
                    <strong>George Waser </strong><i>cc. Dr Raj Goel, Carshalton Fields Surgery</i>
                </div>
                <div className="container">
                    <div className="text">
                        Hi. I have just been discharged by my physio after three months of treatment for sciatica-type pains. The treatment was conscientiously performed; it has led to a gradual reduction, and finally elimination of the pains. It is a pleasure to be able to feel normal again after the crippling pains I had before the treatment started.
                        May I wish you all well at your new treatment centre.
                    </div>
                    <strong>Stephen Brand</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I just wanted to give my feedback. I found the help from the physiotherapist really made a difference to my wrist injury I’d sustained at the gym. She took time to closely assess my arm, identifying the nature of the damage as muscular, and then gave me a series of exercises to help strengthen my wrist. She explained everything in a clear and friendly way, and the exercises have been really effective in hastening my recovery. I’d spent months without improvement and am so pleased to see things move forward now. Thanks so much for all your help.
                    </div>
                    <strong>Dr Andre Morris</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I recently had some physio at your clinic and found it, and the advice given very helpful. My physio was given by Martin Bull who gave me some good tips and also advised me on what to carry on doing. Thanking you once again,
                    </div>
                    <strong>Mrs E.D. Cohen</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I have been attending the physio clinic for the last 6 weeks. The treatment I have been given by Martin has been excellent and my condition has greatly improved due to his knowledge and attention. I would also wish to say that Lorraine and all concerned have been so very kind and helpful and I would certainly attend again if it is deemed necessary.
                    </div>
                    <strong>Dorothy Jedburgh</strong>
                </div>
                <div className="container">
                    <div className="text">
                        I would like to recommend Surrey Physio to everyone. I broke my right foot four years ago and I was in a lot of pain the doctor said I should go to you and you have helped me a lot with the pain and with the help of Marine I am now in less pain I am walking a lot better. So I am telling any one that needs to go there and get help and the people are very nice.
                    </div>
                    <strong>Mrs M. Evans</strong>
                </div>

            </section>
        </Page>);
    }
}
export default Testimonials;
