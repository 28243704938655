import React from 'react';

import BookOnline from "../module/BookOnline/BookOnline";
import Slideemployee from "../module/slide/sticky/slide";


const  HomeBlock4 = () => {
    return <section className="teamAndAppointment">
        <div className="container">
            <div className="row">
                <div className="col-lg-7">
                    <Slideemployee />
                </div>
                <div className="col-lg-5">
                    <BookOnline />
                    {/*<div className="appointment">*/}
                    {/*    <h2 className="sectionTitle center">Make an Appointment</h2>*/}
                    {/*    <div className="book-online-content">*/}
                    {/*        <div className="back-fon"></div>*/}
                    {/*        <div className="book-text"><a href="https://pms.surreyphysio.co.uk/diary/online_booking">BOOK PHYSIO ONLINE</a></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock4;