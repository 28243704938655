import React from 'react';

import rio2016 from '../../access/img/rio2016.png';

import olympics_1 from '../../access/img/olympics_1.png';
import olympics_2 from '../../access/img/olympics_2.png';
import olympics_3 from '../../access/img/olympics_3.png';

import olympics_4 from '../../access/img/olympics_4.png';
import olympics_5 from '../../access/img/olympics_5.png';


import olympics_6 from '../../access/img/olympics_6.png';
import olympics_7 from '../../access/img/olympics_7.png';
import olympics_8 from '../../access/img/olympics_8.png';
import olympics_9 from '../../access/img/olympics_9.png';

import olympics_10 from '../../access/img/olympics_10.png';
import olympics_11 from '../../access/img/olympics_11.png';
import olympics_12 from '../../access/img/olympics_12.png';
import olympics_13 from '../../access/img/olympics_13.png';

import olympics_14 from '../../access/img/2016-07-30_14.17.12.jpg';
import olympics_15 from '../../access/img/2016-08-05_23.44.20.jpg';
import olympics_16 from '../../access/img/2016-08-12_10.19.48.jpg';
import olympics_17 from '../../access/img/2016-08-13_12.06.30.jpg';
import olympics_18 from '../../access/img/Rio_2016_Olympic_medical_team.jpg';

import olympics_19 from '../../access/img/M77A1472.jpeg';
import olympics_20 from '../../access/img/M77A1214b.JPG.jpg';
import olympics_21 from '../../access/img/M77A1166.jpeg';
import olympics_22 from '../../access/img/M77A0254.jpeg';
import Page from "../module/include/Page/Page";

class Olympics extends React.Component {
    state = {
        title: 'Olympics'
    };

    render() {
        return (<Page title={this.state.title}>

            <section className="olympics">
                <div className="container">
                    <h2 className="sectionTitle">Olympics</h2>
                    <h3 className="sectionSubtitle">At Croydon Physio, we’re pleased to let you know that a number of
                        our team
                        have
                        worked with Team GB. We’ve also looked after a number of top athletes, including International
                        tennis
                        players, weightlifters, sprinters, and long distance runners, as well as many athletes from
                        other sports
                        too.</h3>
                    <div className="olympics__top">
                        <div className="olympics__title">PyeongChang 2018</div>
                        <div className="top__image">
                            <img src={olympics_20} alt=""/>
                        </div>
                        <div className="top__desc">
                            <p>Tim was very lucky, a patient at Croydon Physio managed to make contact with a friend who
                                was in the Tonga team, and through that contact Tim went to PyeongChang as the physio
                                for Tonga. A flight to Seoul and a 4 hour bus ride took me to a small ski resort in the
                                middle of nowhere called PyeongChang.</p>
                            <p>Tim had the pleasure of spending the next two weeks with Pita, the athlete, and coach
                                Tomas and Manager Steve, who were absolutely brilliant and a huge pleasure to work with,
                                as were the rest of the wonderful Tonga team.</p>
                            <p>While out in PyeongChang, Tim had the honour to present a talk at the Olympics to doctors
                                and physios from a number of other nations on Advanced Hamstring Rehabilitation.</p>
                            <p>Pita finished 114 out of 119, which was a big achievement for an athlete from a country
                                of small population and no mountains.</p>
                        </div>
                        <div className="olympics-carousel">
                            <div className="col-lg-4">
                                <div className="olympics__img">
                                    <img src={olympics_19} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="olympics__img">
                                    <img src={olympics_21} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="olympics__img">
                                    <img src={olympics_22} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="olympics__top">
                        <div className="olympics__title">RIO 2016</div>
                        <div className="top__image">
                            <img src={rio2016} alt=""/>
                        </div>
                        <div className="top__desc">
                            <p>Tim was over the moon to be back in Rio for the 2016 Olympic Games as a physiotherapist
                                at in the Medical Centre in Athlete's Village working with a mostly Brazilian
                                workforce. </p>
                            <p>The Games were incredible, and athletes treated were from Benin, Guinea-Bissau,
                                Kazakhstan, Peru, Bukina Faso, Morocco, Moldova, Gabon, Cayman Islands, Kenya, Swaziland
                                and others.</p>
                            <p>The Games were a success, but had its challenges, such as the late building of many
                                venues, medical equipment not being available, and the horrific traffic! But the
                                scenery, atmosphere, and craziness of Rio was something else. From Copacabana to Ipanema
                                to Baha to Athlete's Village, It was the Rio Experience. </p>
                        </div>
                        <div className="olympics-carousel">
                            <div className="col-lg-2">
                                <div className="olympics__img">
                                    <img src={olympics_14} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="olympics__img">
                                    <img src={olympics_15} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="olympics__img">
                                    <img src={olympics_16} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="olympics__img">
                                    <img src={olympics_17} alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="olympics__img">
                                    <img src={olympics_18} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="olympics__item">
                        <h2 className="olympics__title">BAKU 2015</h2>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="olympics__desc">
                                            Tim was lucky enough to be one of 60 physiotherapists who were flown to
                                            Baku,
                                            Azerbaijan
                                            for the European Games. Although the Games was not well publicised in the
                                            UK, it was
                                            very popular in Eastern Europe and Russia and the oil-rich Government of
                                            Azerbaijan
                                            spent a reported $7billion on the games, with 6000 athletes and 20 sports
                                            including
                                            athletics, swimming and gymnastics. It is the first time a European Games
                                            has been
                                            held,
                                            and it really was a very well run event.
                                            Tim was based in the Medical Centre Polyclinic in Athlete’s Village, and
                                            treated
                                            many
                                            athletes from most of the European countries.
                                        </div>
                                    </div>
                                    <div className="olympics-carousel">
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_1} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_2} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_3} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mobile_none">
                                <div className="olympics__img">
                                    <img src={olympics_4} alt=""/>
                                    <img src={olympics_5} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="olympics__item">
                        <h2 className="olympics__title">GLASGOW 2014</h2>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="olympics__desc">
                                            Both Tim and Jack were volunteers at the Commonwealth Games in Glasgow in
                                            2014. It
                                            was a
                                            great event, and Glasgow is a beautiful city. Tim was based at Scotstoun
                                            Stadium as
                                            one
                                            of the Lead Physio’s, and Jack was based as a physiotherapist in the medical
                                            centre
                                            in
                                            Athlete’s Village. Both Tim and Jack had a great time and learned a lot as
                                            well as
                                            gaining lots of experience treating elite sports people.
                                        </div>
                                    </div>
                                    <div className="olympics-carousel">
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_7} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_8} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="olympics__img">
                                                <img src={olympics_9} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mobile_none">
                                <div className="olympics__img">
                                    <img src={olympics_6} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="olympics__item">
                        <div className="olympics__title">LONDON 2012</div>
                        <div className="olympics__desc">
                            Croydon Physio had a number of clients who competed at the Olympic Games 2012. These are
                            athletes
                            who
                            are all fully funded, and use the team at Croydon Physio to keep them in shape. Some of
                            these
                            athletes
                            come in multiple times per week to keep them injury free through the season.
                        </div>
                    </div>
                    <div className="olympics__top right">
                        <div className="top__image">
                            <img src={olympics_10} alt=""/>
                        </div>
                        <div className="top__desc">
                            <div className="olympics__author">James Ellington</div>
                            <p>
                                James is currently Britain's top 200m sprinter going into the Olympic Games, having won
                                the
                                British
                                Championships in 2012. He is also a full member of the 4x100m British team. Tim was in
                                Geneva
                                with
                                James in 2012 when he had one of the most important races of the season, gaining the
                                Olympic
                                A-standard qualification time.
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="olympics__top">
                        <div className="top__image">
                            <img src={olympics_11} alt=""/>
                        </div>
                        <div className="top__desc">
                            <div className="olympics__author alignRight">Lawrence Okoye</div>
                            <p className="alignRight">
                                In 2012, Lawrence threw the 4th longest distance in the World going into the Olympics –
                                not
                                bad
                                considering he was only 20 years old! He is the current British record holder, and
                                British
                                champion.
                                Lawrence started seeing us while he was a junior, and we helped him progress to seniors
                                and
                                then the
                                Olympics. During the off-season, he comes into the clinic regularly, and in-between
                                competitions
                                during the season.
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="olympics__top right">
                        <div className="top__image">
                            <img src={olympics_12} alt=""/>
                        </div>
                        <div className="top__desc">
                            <div className="olympics__author">Abdul Buhari</div>
                            <p>
                                Croydon Physio have been looking after Abdul since 2005. In 2010 he won the British
                                Championships,
                                and this year threw over 65m to qualify for the Olympic Games. He is immensely strong
                                and a very
                                loyal patient who we looked after for many years.
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="olympics__item">
                        <div className="row">
                            <div className="col-lg-8">
                                <img src={olympics_13} alt=""/>
                            </div>
                            <div className="col-lg-4">
                                <div className="desc">
                                    <div className="olympics__author alignRight">Casey Stoney</div>
                                    <p className="alignRight">
                                        Casey has been a client of Croydon Physio that started at Addington Palace
                                        in 2005. She is
                                        the Chelsea captain, and recently became Captain of the GB team for the
                                        Olympic Games.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="otherOlympics">
                        <h2 className="otherOlympics__title">Other Croydon physiotherapists involved in the London
                            2012 Olympic and
                            Paralympic Games </h2>
                        <div className="otherOlympics__item">
                            <div className="otherOlympics__author">Matt Lawrence</div>
                            <div className="otherOlympics__desc">
                                Matt was our Lead Physio at the Mitcham branch, and was lead physio for the Men’s GB
                                Goalball team.
                                Matt went to the Paralympic Games with full accreditation.
                            </div>
                        </div>
                        <div className="otherOlympics__item">
                            <div className="otherOlympics__author">Emily Parry</div>
                            <div className="otherOlympics__desc">
                                Emily was also one of our Lead Physio’s and was volunteering at Eton Dorney for the
                                rowing, in the
                                Paralympic Games. Emily
                                works at the Mitcham branch and the Carshalton/Sutton branch.
                            </div>
                        </div>
                        <div className="otherOlympics__item">
                            <div className="otherOlympics__author">Sarah Kursawe</div>
                            <div className="otherOlympics__desc">
                                Sarah was our Lead Physio in Croydon between 2006 and 2011 and was a Lead Physio for
                                UK Athletics
                                specializing in Paralympics, who also went to the Paralympic Games.
                            </div>
                        </div>
                    </div>
                    <div className="olympics__item">
                        <div className="olympics__title">VANCOUVER 2010</div>
                        <div className="olympics__desc">
                            <p>
                                Tim Allardyce, Clinical Director of Croydon Physio, volunteered to be part of the
                                Ghana Ski Team,
                                and assisted the team with training camps in Val Di Femme in Italy, and Mount
                                Washington on
                                Vancouver Island, before travelling with the team to the Winter Olympic Games. Tim
                                and the team were
                                based in Mount Washington for around 7 days prior to the Olympics, before moving to
                                Pemberton, about
                                1 hour north of Whistler, and then when the games started, the team was based in
                                Whistler itself.
                                During competition, Tim was based in Athlete’s Village.
                            </p>
                            <p>
                                The Winter Olympics was an incredible experience, life-learning time for Tim. No
                                extended work trip
                                is ever easy, but the life journey was good, and there were many incredible and
                                positive
                                experiences.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}

export default Olympics;