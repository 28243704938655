import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {NavLink, Route} from "react-router-dom";

import employees1 from "../../../../access/img/employees/Peter.jpeg";
import employees2 from "../../../../access/img/employees/DSC08720b-214x321.jpg";
import employees3 from "../../../../access/img/employees/Martin.jpeg";

const  slide = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1
    };
    return (
        <div className="employeesCarusel">
            <h3>Meet Our Team</h3>
            <Slider {...settings}>
                <div className="team">
                    <div className="team_img">
                        <NavLink to={'/employees/id/5'}><img src={employees1} alt="" /></NavLink>
                    </div>
                    <div className="sectionTitle"><NavLink to={'/employees/id/5'}>Peter</NavLink></div>
                </div>
                <div className="team">
                    <div className="team_img">
                        <NavLink to={'/employees/id/3'}><img src={employees2} alt="" /></NavLink>
                    </div>
                    <div className="sectionTitle"><NavLink to={'/employees/id/3'}>Kay Allardyce</NavLink></div>
                </div>
                <div className="team">
                    <div className="team_img">
                        <NavLink to={'/employees/id/4'}><img src={employees3} alt="" /></NavLink>
                    </div>
                    <div className="sectionTitle"><NavLink to={'/employees/id/4'}>Martin</NavLink></div>
                </div>
            </Slider>
        </div>
    );
}
export default slide;
