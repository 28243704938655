import React from 'react';
import team from '../../access/img/team.png';
import addImg from '../../access/img/addImg.svg';
const  HomeBlock5 = () => {
    return <section className="reviews">
        <div className="container">
            <h2 className="sectionTitle">What our patients say</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            My name is Mr Jeff David and was receiving Physio for severe neck pains caused during a whiplash injury. My sessions are now completed and I would like to say thank you to my Physio Jamie. My Physio sessions have helped me to recover from my injury and I would recommend Jamie as a great Physio.
                        </p>
                        <div className="author">Jeff David</div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="review">
                        <p>
                            Many thanks for the Physio carried out on me over the last 5 weeks. It really has helped me immensely. A personal thanks to Jamie Wilson who kept me informed at all times on how I was progressing and the exercises he recommended for me really did make a huge difference. Many Thanks.
                        </p>
                        <div className="author">John Fraser</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}
export default HomeBlock5;
