import React from 'react';
import Page from "../module/include/Page/Page";
import testimonial from "../../access/img/testimonial.jpg";

class Physio extends React.Component {
    state = {
        title: 'Olympics'
    };

    render() {
        return (<Page title={this.state.title}>

            <section className="olympics price">
                <div className="container">
                    <h2 className="sectionTitle">Surrey Physio Services</h2>
                    <div className='Physio'>
                        <div>
                            <h4 dir="ltr">Manipulative Physiotherapy</h4>
                            <img className=" wp-image-263 alignright" title="Manipulative Physiotherapy"
                                 src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/Croydonphysio-300x225.jpg"
                                 alt="" width="270" height="203"/>
                            Manipulative physiotherapy involves the precise assessment of
                            musculoskeletal ailments. A careful history is taken during the subjective examination,
                            which allows the therapist to organise their thoughts and ideas (“working hypothesis”). This rigorous
                            process allows the therapist to optimise the physical examination.

                            During the physical examination the therapist confirms or negates their “working hypothesis”
                            in order to ascertain which structures are involved in the dis-function. Such a process allows
                            the instigation of efficacious time effective treatment regimes.

                            Treatment techniques vary but should be directed by clinical reasoning to the normalisation
                            of signs and symptoms.
                            <h4 dir="ltr">Sports Physiotherapy</h4>
                            Surrey Physio is closely linked with a number of sports centres in Sutton, Croydon and
                            Mitcham and we cannot stress strongly enough the importance of general fitness and well-being in
                            today’s society.

                            Whether you are an elite athlete, a sports enthusiast or merely a spectator, we treat
                            each case individually, and with our full attention. At Surrey Physio, we also treat patients
                            suffering from long-term pain and symptoms.

                            Sports injuries need to be treated swiftly to ensure that no further issues occur. These
                            injuries require exercise rehabilitation, treatment and our undivided attention. We aim
                            to get
                            you back to your former glory as quickly as possible, by taking you through the correct
                            treatment programme. We aim to restore strength, proprioception, flexibility and agility
                            from early stage rehab through to supervised gym / Pilates work to minimise future risk of injury.
                            <div>
                                <h4 dir="ltr">Biomechanical Assessment</h4>
                                <img className="alignleft" title="Gait Analysis"
                                     src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/IMG_0502-300x200.jpg"
                                     alt="" width="270" height="180"/>Biomechanical assessment – we offer a
                                comprehensive
                                biomechanical service to identify problems related to your painful foot, knee,
                                and hip
                                presentation. A biomechanical assessment is one hour, which will consist of a
                                static and
                                dynamic foot scan, clinical assessment procedures, exercise prescription, and a
                                plan of
                                treatment is required. The Foot Scan will provide detailed information on forces
                                imparted on
                                to the feet when static and when walking/running. Additionally we will be able
                                to
                                extrapolate information to produce bespoke orthotics, which will correct the
                                faulty
                                biomechanics identified. The bespoke orthotics will be made by a specialist
                                orthoses
                                manufacturer in partnership with the clinic.

                                Biomechanical costs:
                                Initial assessment – £70.
                                Option 1: Bespoke orthotics – £150 (with life time guarantee).
                                Option 2: Off the shelf orthotics with amendments – range between £50-70.
                                Lifespan depending
                                on activity level up to 1 year.
                                Review consultation and fitting – £35.

                                <div>
                                    <h4 dir="ltr">Clinical Pilates</h4>
                                    Pilates is a specific system of exercises. It is named after its founder
                                    Joseph Pilates,
                                    who began to develop his ideas in a World War I prisoner of war camp while
                                    helping
                                    injured prisoners to recover from war injuries. He then moved to New York in
                                    the 1920’s,
                                    where he set up a studio.
                                    In the early days his exercises were embraced by the dance world, who found
                                    the
                                    movements strengthened and enhanced performance. In the event of an injury,
                                    Plates
                                    exercises also maintained strength and assisted with rehabilitation.

                                    More recently, physiotherapists have embraced Pilates because it provides
                                    core stability
                                    and specific strength needed by so many of our clients experiencing
                                    persistent back
                                    pain. The programme includes up to 250 exercises, focussing on the use of
                                    the deep
                                    abdominal muscles, accurate breathing and good posture. The exercises can be
                                    very gentle
                                    and doesn’t aggravate the pain. For clients with acute and sub-acute pain,
                                    we use
                                    exercises that activate the deep stomach and back musculature to develop
                                    strength and
                                    control around their weakened spinal segments.

                                    The exercises at the other end of the spectrum are designed to be very
                                    challenging,
                                    requiring a very high level of core stability. These are excellent for elite
                                    athletes to
                                    provide that edge over the opposition or to sharpen up slightly weak core
                                    stabilisers.
                                    Exercises for elite sports people have a sports specific slant, where we
                                    design
                                    exercises with the challenges of the sport in mind.
                                    Exercises for dance professionals and students have a dance specific slant
                                    where we
                                    design exercises with the demands of dance technique in mind.

                                    {/*<a href="http://www.surreyphysio.co.uk/physio/kay-allardyce/">Kay*/}
                                    {/*    Allardyce</a>*/}
                                    runs our Pilates classes that included one-to-one, at an affordable price of just
                                    £50
                                    per hour, or 4 to-one classes where you get the individual care in a group class, at
                                    just £15 per hour.
                                    <div>
                                        <h4 dir="ltr">Sports and Therapeutic Massage</h4>
                                        <img className="alignright" title="Sports and Therapeutic Massage"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/Croydon-Acupuncture-300x225.jpg"
                                             alt="" width="270" height="203"/>Massage is a hands-on-treatment,
                                        which employs
                                        a variety of techniques to create changes in the soft tissues of the
                                        body such as
                                        skin fascia, muscles, tendons and ligaments. Vigorous or slow
                                        application of massage
                                        can help reduce the level of pain or stress and improve general well
                                        being.
                                        Sports massage can be used pre- and post sports performance. It
                                        utilises techniques
                                        that can assist elite and recreational sports performers in the
                                        prevention of
                                        injury, improvement of performance and in the reduction of muscle
                                        soreness and scar
                                        tissue formation.

                                        Therapeutic massage uses techniques which improve circulation,
                                        decrease muscle
                                        tension and can help to alleviate physical and psychological
                                        stresses in the body.
                                        <h4>Exercise Rehabilitation</h4>
                                        <img className="wp-image-259 alignleft"
                                             title="Exercise Rehabilitation"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/CNV00063cropped-300x282.jpg"
                                             alt="" width="270" height="254"/>
                                        Rehabilitation is a form of treatment used to
                                        educate a patient so they can safely return to sport, work or
                                        leisure activities and
                                        enhance their sporting performance. Surrey Physio has a Pilates
                                        / Rehab studio used solely for this purpose.

                                        We use other equipment in our studio such as: wobble boards,
                                        Swiss balls, steps,
                                        weights, biofeedback machine, gait scanners, and other rehab
                                        equipment.

                                        Physios use a variety of techniques to rehabilitate their
                                        patients and all exercise
                                        programmes are personalised. Our clinicians also work closely
                                        with the in-house gym

                                        <img title="Dot"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/dot.jpeg"
                                             alt="" width="25" height="10"/>Mobilisations

                                        <img title="Dot"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/dot.jpeg"
                                             alt="" width="25" height="10"/>Soft Tissue Massage

                                        <img title="Dot"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/dot.jpeg"
                                             alt="" width="25" height="10"/>Stretches

                                        <img title="Dot"
                                             src="http://www.surreyphysio.co.uk/wp-content/uploads/2012/01/dot.jpeg"
                                             alt="" width="25" height="10"/>
                                        Pilates Patients who have been injured or immobilised
                                        for periods of time may be affected by
                                        muscle weakness and this is when rehab is used
                                        to re-build and strengthen.

                                        Surrey Physio encompasses clinical Pilates
                                        within their rehabilitation programmes to
                                        increase the bodies core stability, (the
                                        strengthening of the stomach muscles to
                                        increase posture and spinal strength) to assist
                                        in the prevention of further
                                        problems.
                                        <div className="clearfix"></div>
                                        <h4>Acupuncture</h4>
                                        Acupuncture is a 2,000 year old medical
                                        philosophy, which aims to balance the flow
                                        of energy within the body. It is used to address
                                        any imbalances which is the cause
                                        of many physical disorders and conditions of
                                        emotional distress. Acupuncture treats
                                        both the symptoms of the illness and it’s
                                        underlying cause.
                                        <div>
                                            <h4 dir="ltr">Does it Hurt?</h4>
                                            There may well be a sensation when the
                                            needle makes contact with the skin, and
                                            again when it enters the muscle which feels
                                            like a pinch, but this only lasts
                                            for the first few seconds at initial
                                            contact.
                                            <br/>
                                                <br/>
                                                    <strong id="internal-source-marker_0.5896524577401578">
                                                        <span style={{color: "#ff0000"}}>*</span>
                                                        We have five physiotherapists
                                                        who
                                                        are qualified to administer
                                                        acupuncture and some use this
                                                        alongside treatment programmes,
                                                        dependant upon the
                                                        individual and the
                                                        condition.
                                                    </strong>
                                        </div>
                                        <h4>Local Gyms</h4>

                                        We are pleased to be associated with
                                        <strong>Pulse Health and Fitness Gym</strong>
                                        in Carshalton <br/>
                                        {/*<a*/}
                                        {/*    href="http://www.pulsecarshalton.co.uk/index.html"*/}
                                        {/*    target="_blank">www.pulsecarshalton.co.uk</a>.*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Page>
    );
    }
    }

    export default Physio;
