import React from 'react';

import person_1 from '../../access/img/employees/holly_l.jpg';
import person_2 from '../../access/img/employees/mo_l.jpg';
import person_3 from '../../access/img/employees/lottie_l.jpg';
import person_4 from '../../access/img/employees/Steve-Photo-Medium-150x150.png';
import person_5 from '../../access/img/employees/steve_l.jpg';
import person_6 from '../../access/img/employees/Leila2-214x199.jpg';
import person_7 from '../../access/img/employees/DSC08720b-214x321.jpg';
import person_8 from '../../access/img/employees/Martin.jpeg';
import person_9 from '../../access/img/employees/Peter.jpeg';

import Page from "../module/include/Page/Page";
import {NavLink, Route} from "react-router-dom";
import Employees from "./employees/employees";


class meetTeem extends React.Component {
    state = {
        title: 'Meet the Team'
    };

    render() {
        const apiEmployees = [
            // {'id': '6', 'title': 'Holly Cooper', 'profession': 'BSc (Hons) Physiotherapy', 'desc': <div><p>Holly is a Chartered Physiotherapist who qualified in 2011 from Brighton University. Originally after qualifying she moved back to Swansea to work as musculoskeletal physio in a large physiotherapy clinic, Physiotherapy Wales. While in Wales she has also worked with in a rugby club, and with local sports teams. After that, she decided to travel the world for a half a year before moving to Portsmouth to work for another private practice physiotherapy clinic.</p>
            //         <p>Following this, Holly moved to London to work for Surrey Physio where she is a fantastic member of the team. Holly is incredibly personable, hands on, and a brilliant rehab member who will help you recover from your injuries. She has always worked in the private sector with NHS and private patients . She says: “I have always loved working with musculoskeletal patients and sporting injuries”. She is also trained in Pilates.</p>
            //         <p>Holly just loves sport , and has played netball nationally and plays every week with a local team.</p></div>, 'img': person_1},
            // {'id': '5', 'title': 'Mo Sillah-Freckleton', 'profession': '', 'desc': '', 'img': person_2},
            // {'id': '4', 'title': 'Lottie Betchley', 'profession': 'BSc (Hons) Physiotherapy, MCSP, HPCP Registered, Pilates', 'desc': <div><p>Lottie qualified as a physiotherapist from King’s College London University in 2012 and has worked in private practice since. She has been working in neurology treating young people with brain and spinal cord injuries, as well as people with degenerative neurological conditions including multiple sclerosis. Alongside this she has worked in musculoskeletal medicine and regularly treats clients in their own homes or at the Carshalton Physio Clinic.</p>
            //         <p>Lottie is a qualified Pilates instructor and regularly incorporates core strengthening into the rehabilitation of spinal dysfunction.</p>
            //         <p>Lottie enjoys horse riding, particularly show jumping, and is currently completing a Masters degree in Veterinary Physiotherapy. Lottie is a lovely physio and will look after you well.</p></div>, 'img': person_3},


            {'id': '5', 'title': 'Peter', 'profession': '', 'desc': <div></div>, 'img': person_9},
            {'id': '4', 'title': 'Martin', 'profession': '', 'desc': <div><p>Martin completed an MSc in Neuromusculoskeletal Physiotherapy from the University School of Physical Education in Poland in 2007. He also gained a wide variety of experience in treating both acute and chronic musculoskeletal conditions, while working in hospitals and private clinics in Poland, before coming to the UK</p>
                    <p>After gaining some invaluable experience working in the community, mostly liaising with the Case Management Company, he joined the Surrey Physio team in 2013 to work in a multi-disciplinary sports and spinal clinic.
                        Martin uses a variety of treatment modalities including massage, mobilisation, electrotherapy, exercise, and he also offers kinesiology taping as an adjunct to manual therapy when appropriate. He has completed Acupuncture accredited courses through the AACP in the UK and along with other modalities implementing it in his work. He mainly uses McKenzie MDT for assessment and treatment of variety of spinal, lower and upper limb conditions.</p>
                    <p>He has a strong belief that restoring functional movement, helping the patient to understand the cause of their problem and teaching them how to prevent further injury will help patients to become stronger and move again.
                        Martin’s interests outside of work include climbing, sailing, mountain biking and sailing. He spends most of his time entertaining his little children.</p></div>, 'img': person_8},
            {'id': '3', 'title': 'Kay Allardyce', 'profession': 'BSc (Hons) Osteopathy, GOsC Registered, Pilates', 'desc': <div><p>Kay has been an Osteopath since 2005 and trained at The British College of Osteopathic Medicine. She is also qualified in modern acupuncture/ dry needling, which is used for some musculo skeletal problems. Kay will use cranial osteopathy in some treatments, especially when treating infants and babies. Kay is also a qualified Pilates instructor. Kay will do a thorough assessment initially, looking at posture, flexibility and spinal range of motion. Specific exercises are then prescribed helping to strengthen and mobilise according to your individual body needs.</p>
                    <p>Classes are either on a one to one basis or small groups of 3-4. This is to allow Kay to observe and correct technique throughout the whole session, reducing the risk of inaccurate and potentially injurious performance, which so many patients have reported in large group exercise classes.</p></div>, 'img': person_7},
            {'id': '2', 'title': 'Steve Cole', 'profession': 'B.Ost, GOsC Registered', 'desc': <div><p>Steve is an experienced osteopath who graduated from The British School of Osteopathy in 2005 with distinction.
                    Since graduating Steve has worked at a number of well established clinics both locally and in the city and is now establishing his own practice here at Surrey Physio. In addition he continues to lecture at The British School of Osteopathy and works at another community-based private clinic in Caterham. He has experience in treating a wide variety of patients, ranging from children to the elderly, pregnant women and elite sports people. However his main interest is in the treatment and rehabilitation of sports injuries. He has an excellent reputation locally having worked in Carshalton for the last 10 years. Steve is registered with all the major insurance companies.</p>
                    <p>In his free time Steve enjoys playing golf and spending time with his young family.</p>
                    <p>To book an appointment with Steve please call 020 3488 0585 or 020 3488 0585.</p></div>, 'img': person_5},
            {'id': '1', 'title': 'Leila Williams', 'profession': 'Carshalton/Sutton Clinic Manager, Practice Manager, Carshalton and Sutton Physio', 'desc': <div><p>Leila is the practice manager at Carshalton and Sutton. She has been with the Surrey Physio team since 2008 and has worked alongside Lorraine to help her run the clinic. Leila is a fantastic leader, and great at organising the Mr Surrey Physio christmas challenge. If you need Leila’s help call her on 0208 643 8000 or drop an e-mail to leila@surreyphysio.co.uk</p></div>, 'img': person_6}
        ];

        const Employee = apiEmployees.map(item => <div>
            <Route path={'/employees/id/'+item.id}><Employees profession={item.profession} title={item.title} desc={item.desc} img={item.img} /></Route>
        </div>);

        return (<Page title={this.state.title}>
            <section className="meetTheTeam">
                <div className="container">
                    {Employee}
                </div>
            </section>
        </Page>);
    }
}
export default meetTeem;