import React from 'react';
import Page from "../../module/include/Page/Page";

class employees extends React.Component {
    state = {
        title: 'employees'
    };
    render() {
        return (<Page title={this.state.title}>
            <section className="pilates">
                <div className="container">
                    <h2 className="sectionTitle">{this.props.title}</h2>
                    <h3 className="sectionSubtitle">{this.props.profession}</h3>
                    <div>
                        <div className="blockImg"><img src={this.props.img} />{this.props.desc}</div>
                    </div>
                </div>
            </section>
        </Page>);
    }
}
export default employees;