import React from 'react';

const  Footer = () => {
    return <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="links">
                        <div className="footerTitle">Clinics</div>
                        <ul className="links__ul">
                            <li><a href="http://www.balhamphysio.co.uk/">Balham Physio</a></li>
                            <li><a href="http://www.batterseaphysio.co.uk/">Battersea Physio</a></li>
                            <li><a href="https://www.sutton-physio.co.uk/#/">Sutton Physio</a></li>
                            <li><a href="http://www.wallingtonphysio.co.uk/">Wallington Physio</a></li>
                            <li><a href="http://www.carshaltonphysio.co.uk/">Carshalton Physio</a></li>
                            <li><a href="http://www.mitchamphysio.co.uk/">Mitcham Physio</a></li>
                            <li><a href="https://www.croydonphysio.co.uk/#/">Croydon Physio</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="links">
                        <div className="footerTitle">Contact us</div>
                        <div className="footer__phone">
                            Tel: <b>0208 643 8000</b>
                        </div>
                        <div className="footer__phone">
                            Fax: <b>0208 643 8001</b>
                        </div>
                        <div className="footer__address">
                            <div>ADDRESS:</div>
                            <b>Surrey Physio, 2 Coleridge Ave,
                                Sutton, SM1 3RQ</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}
export default Footer;

