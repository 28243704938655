import React from 'react';

const  HomeBlock3 = () => {
    return <section className="weUse">
        <div className="container">
            <h2>Sutton Physio – Welcome to our site!</h2>
            <p>Surrey Physio – established in 2000 – comprises a team of highly skilled clinicians, specialising in the diagnosis and treatment of sports injuries, as well as spinal and joint pain. Our physiotherapy clinics are located in Croydon, East Croydon, Sutton, Balham and Mitcham.
                Our treatment combines manipulative physiotherapy, exercise based rehab programmes, soft tissue work, acupuncture and plenty of useful advice, serving to produce the best clinical results and patient satisfaction.</p>

            <p>We look after a number of International athletes, including Olympians, a number of the UK’s top athletes, world champions in various sports, International tennis players, and a number of high profile sportsmen and women in a number of track and field sports.</p>

            <p>We use a hands-on approach to treatment. We actively treat your pain, and have an number of other tools in our toolbox to ensure a speedy recovery.</p>

            <p>We don’t just treat SPORTS INJURIES! We treat every day injuries, elderly, young people, work injuries, and aches and strains.</p>

            <p>We are RECOMMENDED BY GPs, CONSULTANTS, and SPORTS COACHES throughout CROYDON, SUTTON, BALHAM and MITCHAM.</p>

            <p>Our promise to you: We will try our very best to get you better as quickly as possible, helping you to get back on the playing field in the minimal time, or back to work quicker at minimal cost.</p>

        </div>
    </section>
}
export default HomeBlock3;
